<template>
    <div class="task_static">
        <div class="filter">
            <div class="search">
                <div class="item">
                    <div class="left">任务类别：</div>
                    <div class="right">
                        <el-select size="medium" v-model="task_type" popper-class="global_select_popper"
                                   @change="search">
                            <el-option v-for="item in taskType" :key="item.value" :label="item.label"
                                       :value="item.value"></el-option>
                        </el-select>
                    </div>
                </div>
                <div class="item">
                    <div class="left">任务状态：</div>
                    <div class="right">
                        <el-select size="medium" v-model="task_status" popper-class="global_select_popper"
                                   @change="search">
                            <el-option v-for="item in taskStatus" :key="item.value" :label="item.label"
                                       :value="item.value"></el-option>
                        </el-select>
                    </div>
                </div>
                <div class="item">
                    <div class="left">任务年度：</div>
                    <div class="right">
                        <el-date-picker size="medium" v-model="task_year" type="year" value-format="yyyy"
                                        placeholder="请选择任务年度" @change="search"></el-date-picker>
                    </div>
                </div>
                <div class="item">
                    <div class="left">负责人：</div>
                    <div class="right">
                        <el-tooltip placement="top" :content="fuze_users.map(item=>item.dingding.nickname).join('、')"
                                    popper-class="single-tooltip" :disabled="fuze_users_over" :open-delay="300">
                            <div class="users" id="fuze_users_box" @click="choosePeople('fuze_users')">
                                <span class="placeholder" v-if="!fuze_users.length">请选择</span>
                                <span
                                    id="fuze_users">{{ fuze_users.map(item => item.dingding.nickname).join('、') }}</span>
                                <i class="el-icon-circle-close" v-if="fuze_users.length>0"
                                   @click.stop="fuze_users=[];search();"></i>
                            </div>
                        </el-tooltip>
                    </div>
                </div>
                <div class="item">
                    <div class="left">承办人：</div>
                    <div class="right">
                        <el-tooltip placement="top" :content="deal_users.map(item=>item.dingding.nickname).join('、')"
                                    popper-class="single-tooltip" :disabled="deal_users_over" :open-delay="300">
                            <div class="users" id="deal_users_box" @click="choosePeople('deal_users')">
                                <span class="placeholder" v-if="!deal_users.length">请选择</span>
                                <span
                                    id="deal_users">{{ deal_users.map(item => item.dingding.nickname).join('、') }}</span>
                                <i class="el-icon-circle-close" v-if="deal_users.length>0"
                                   @click.stop="deal_users=[];search();"></i>
                            </div>
                        </el-tooltip>
                    </div>
                </div>
            </div>
            <div class="add_btn">
                <el-input size="medium" placeholder="关键字搜索..." prefix-icon="el-icon-search"
                          v-model="search_value" @input="search" clearable></el-input>
            </div>
        </div>
        <div class="table">
            <el-table :data="tableData" style="width:100%" height="100%" v-loading="loading">
                <el-table-column label="序号" align="center">
                    <template slot-scope="scope">
                        <div class="serial_number">{{ scope.row.index }}</div>
                        <div class="task_status overdue" v-if="scope.row.no_report">
                            <div>逾期未上报</div>
                        </div>
                        <div class="task_status finished" v-if="scope.row.status==4">
                            <div>任务已完成</div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="任务名称" align="center">
                    <template slot-scope="scope">
                        <taskDialog :enter="3" :task_title="scope.row.task_title" :task_info="scope.row"
                                    @refresh="statisticsLists"/>
                    </template>
                </el-table-column>
                <el-table-column label="任务类别" align="center">
                    <template slot-scope="scope">
                        <span>{{ initTaskType(scope.row.task_type) }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="任务负责人" align="center">
                    <template slot-scope="scope">
                        <span
                            v-if="scope.row.fuze_uids">{{ scope.row.fuze_uids.map(item => item.dingding.nickname).join('、') || '-' }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="任务承办人" align="center">
                    <template slot-scope="scope">
                        <span
                            v-if="scope.row.deal_uids">{{ scope.row.deal_uids.map(item => item.dingding.nickname).join('、') || '-' }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="任务起止时间" align="center" width="210px">
                    <template slot-scope="scope">
                        <span v-if="scope.row.start_time&&scope.row.end_time">
                            {{ scope.row.start_time.slice(0, 10).replace(/-/g, '.') }}
                            -
                            {{ scope.row.end_time.slice(0, 10).replace(/-/g, '.') }}
                        </span>
                    </template>
                </el-table-column>
                <el-table-column label="按时上报" align="center">
                    <template slot-scope="scope">
                        <span v-if="scope.row.on_time!==undefined&&scope.row.on_time!==null&&scope.row.on_time!==''"
                              style="color:#34c30d;">{{ scope.row.on_time }}次</span>
                        <span v-else>-</span>
                    </template>
                </el-table-column>
                <el-table-column label="超时上报" align="center">
                    <template slot-scope="scope">
                        <span v-if="scope.row.out_time!==undefined&&scope.row.out_time!==null&&scope.row.out_time!==''"
                              style="color:#f85451;">{{ scope.row.out_time }}次</span>
                        <span v-else>-</span>
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center">
                    <template slot-scope="scope">
                        <div class="operation">
                            <el-popover placement="bottom" width="100" :open-delay="200" trigger="hover"
                                        :visible-arrow="false" popper-class="operation_popover"
                                        transition="el-zoom-in-top">
                                <ul>
                                    <li>
                                        <reportList :enter="1" :task_info="scope.row"></reportList>
                                    </li>
                                    <li>
                                        <addAppraise :enter="1" :task_info="scope.row"></addAppraise>
                                    </li>
                                    <li>
                                        <addRegister :enter="1" :task_info="scope.row"></addRegister>
                                    </li>
                                    <li>
                                        <taskDialog :enter="3" :task_info="scope.row" @refresh="statisticsLists"/>
                                    </li>
                                    <li>
                                        <taskDialog :enter="2" :task_info="scope.row" @refresh="statisticsLists"/>
                                    </li>
                                    <li>
                                        <delTask :enter="1" :task_info="scope.row"
                                                 @refresh="statisticsLists(true)"></delTask>
                                    </li>
                                </ul>
                                <img slot="reference" src="../../../assets/img/caozuo.png" alt="">
                            </el-popover>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pagination">
                <el-pagination @current-change="pageChange" :current-page="page" :page-size="pagesize"
                               layout="total, prev, pager, next, jumper" :total="total"></el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import api from '@/api/methods/index';
import taskDialog from '@c/task/task_dialog.vue';
import computedData from '@/mixins/computedData.js';
import reportList from '@c/task/report/report_list.vue';
import addAppraise from '@c/task/appraise/add_appraise.vue';
import addRegister from '@c/task/register/add_register.vue';
import delTask from '@c/task/delete/delete_task.vue';
import peoplePicker from '@/utils/people_picker';

export default {
    components: {taskDialog, reportList, addAppraise, addRegister, delTask},
    mixins: [computedData],
    data() {
        return {
            tableData: [],
            page: 1, pagesize: 15, total: 0,
            loading: false,
            taskType: [
                {value: 0, label: '全部(默认)'},
                {value: 1, label: '项目类'},
                {value: 2, label: '党建类'},
                {value: 4, label: '日常管理类'},
            ],
            task_type: 0,
            taskStatus: [
                {value: 1, label: '待办任务'},
                {value: 2, label: '已完成任务'},
            ],
            task_status: 1,
            search_value: '', task_year: new Date().getFullYear().toString(),
            fuze_users: [], deal_users: [], fuze_users_over: true, deal_users_over: true,
        }
    },
    created() {
        this.statisticsLists();
    },
    computed: {
        fuze_users_id() {
            return this.fuze_users.map(item => item.dingding.userid).join(',')
        },
        deal_users_id() {
            return this.deal_users.map(item => item.dingding.userid).join(',')
        },
    },
    methods: {
        statisticsLists(refresh = false) {
            this.loading = !refresh;
            if (!refresh) this.tableData = [];
            let param = {
                page: this.page,
                pagesize: this.pagesize,
                task_status: this.task_status
            };
            if (this.task_type) param.task_type = this.task_type;
            if (this.task_year) param.task_year = this.task_year;
            if (this.search_value) param.search = this.search_value;
            if (this.fuze_users.length > 0) param.fuze_users = this.fuze_users.map(item => item.dingding.userid).join(',');
            if (this.deal_users.length > 0) param.deal_users = this.deal_users.map(item => item.dingding.userid).join(',');
            api.task.statisticsLists(param).then(res => {
                if (res.data) {
                    this.total = res.data.count || 0;
                    this.tableData = res.data.lists || [];
                    if (this.tableData.length > 0 && res.data.user_ifo && res.data.user_ifo.length > 0) {
                        this.tableData.forEach((item, index) => {
                            item.create_uid = {};
                            item.fuze_uids = [];
                            item.deal_uids = [];
                            item.index = ((this.page - 1) * this.pagesize + index) + 1;
                            item.no_report = false;//是否有逾期上报
                            res.data.user_ifo.forEach(user => {
                                if (user.task_id === item.task_id) {
                                    if (user.user_type == 1) {
                                        item.create_uid = user
                                    }
                                    if (user.user_type == 2) {
                                        item.fuze_uids.push(user)
                                    }
                                    if (user.user_type == 4) {
                                        item.deal_uids.push(user)
                                    }
                                }
                            })
                            // if(res.data.no_report_task&&res.data.no_report_task.length>0){
                            //     res.data.no_report_task.forEach(no_report_task=>{
                            //         if(no_report_task.task_id===item.task_id&&item.task_id!=164){
                            //             item.no_report=true;
                            //         }
                            //     })
                            // }
                        })
                    }
                }
                this.loading = false;
            })
        },
        search() {
            this.page = 1;
            this.statisticsLists();
        },
        pageChange(page) {
            this.page = page;
            this.statisticsLists();
        },
        //选择人员
        choosePeople(data) {
            // this[data].push(
            //     {
            //         dingding: {
            //             avatar: '',
            //             nickname: '唐春娅',
            //             userid:'21545498784354645'
            //         }
            //     }
            // )
            // this.search();
            // return;
            peoplePicker.peoplePicker(this[data].map(item => item.dingding.userid), (res) => {
                let users = [];
                res.users.forEach(user => {
                    if (user.emplId) {
                        users.push(
                            {
                                dingding: {
                                    avatar: user.avatar,
                                    nickname: user.name,
                                    userid: user.emplId
                                }
                            }
                        )
                    }
                });
                this[data] = users;
                this.search();
            }, true)
        },
    },
    watch: {
        'fuze_users_id': {
            handler: function (val) {
                this.$nextTick(() => {
                    let data_width = document.getElementById('fuze_users').offsetWidth;//文本内容宽度
                    let box_width = document.getElementById('fuze_users_box').clientWidth;//文本box宽度
                    this.fuze_users_over = data_width < (box_width - 20);
                })
            },
            immediate: true
        },
        'deal_users_id': {
            handler: function (val) {
                this.$nextTick(() => {
                    let data_width = document.getElementById('deal_users').offsetWidth;//文本内容宽度
                    let box_width = document.getElementById('deal_users_box').clientWidth;//文本box宽度
                    this.deal_users_over = data_width < (box_width - 20);
                })
            },
            immediate: true
        }
    }
}
</script>

<style scoped lang="less">
.task_static {
    height: 100%;
    display: flex;
    flex-direction: column;

    .filter {
        display: flex;
        padding: 10px 0;

        .search {
            flex: 1;
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            .item {
                display: flex;
                align-items: center;
                margin-right: 30px;
                margin-bottom: 10px;
                width: 20%;
                min-width: 200px;
                max-width: 230px;

                .left {
                    /*flex-shrink: 0;*/
                    flex: 0 0 70px;
                    text-align: right;
                }

                .right {
                    flex: 1;
                    min-width: 0;

                    .placeholder {
                            color: #ccc;
                    }

                    /deep/ .el-date-editor {
                        width: 100%;
                    }
                }

                .users {
                    width: 100%;
                    height: 36px;
                    line-height: 36px;
                    color: #666;
                    border: 1px solid #DCDFE6;
                    border-radius: 4px;
                    cursor: pointer;
                    transition: all 0.3s;
                    box-sizing: border-box;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    position: relative;
                    padding: 0 10px;

                    /deep/ .el-icon-circle-close {
                        color: #c0c4cc;
                        position: absolute;
                        top: 10px;
                        right: 5px;
                        opacity: 0;
                    }

                    &:hover {
                        border-color: #C0C4CC;

                        /deep/ .el-icon-circle-close {
                            opacity: 1;
                        }
                    }
                }
            }
        }

        .add_btn {
            width: 15%;
            margin-left: 10px;
        }
    }

    .table {
        flex: 1;
        min-height: 0;

        /deep/ tbody {
            tr {
                td:first-of-type {
                    position: relative;
                    padding: 15px 0;
                    overflow: hidden;

                    .task_status {
                        position: absolute;
                        font-size: 12px;
                        padding: 0 20px;
                        line-height: 20px;
                        left: -28px;
                        top: 17px;
                        transform: rotate(-45deg);

                        div {
                            color: #fff;
                            transform: scale(0.75);
                        }

                        &.overdue {
                            background: #ff4848;
                        }

                        &.finished {
                            background: #4dc21e;
                        }
                    }
                }
            }
        }
    }
}
</style>